import { render, staticRenderFns } from "./CoreBlockHeroComponent.vue?vue&type=template&id=31ddab50"
import script from "./CoreBlockHeroComponent.vue?vue&type=script&lang=js"
export * from "./CoreBlockHeroComponent.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockHeroComponent.vue?vue&type=style&index=0&id=31ddab50&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockBreadcrumbs: require('/usr/src/app/Core.Library/src/components/block/CoreBlockBreadcrumbs.vue').default,CoreBlockHeroBackground: require('/usr/src/app/Core.Library/src/components/block/CoreBlockHeroBackground.vue').default,CoreIcon: require('/usr/src/app/Core.Library/src/components/icon/CoreIcon.vue').default,CoreBlock: require('/usr/src/app/Core.Library/src/components/block/CoreBlock.vue').default})
